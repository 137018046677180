import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Button from './Button'

const Wrapper = styled.section`
  position: relative;
  // margin-top: 90px;
  min-height: 300px;

  & .hero-background {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    min-height: 600px;
    // max-height: 100vh;
    height: 100%;

    & .hero-background-left{
      width: 70%;
      background: ${props => props.theme.colors.tan};
    }

    & .hero-background-right{
      width: 30%;
      background: ${props => props.theme.colors.white};
    }
  }

  & .hero-content {
    position: relative;
    display: flex;
    flex-direction: row;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 600px;
    // max-height: 100vh;
    padding: 153px 0 68px 0;
    max-width: ${props => props.theme.sizes.maxNavWidth};
    left: 50%;
    transform: translate(-50%, 0%);

    & .hero-content-left{
      width: 45%;
      align-self: center;
    }

    & .hero-content-right{
      width: 65%;
      align-self: center;
    }
  }

  @media screen and (max-width: 1470px) {
    & .hero-content .hero-content-left {
      padding-left: 32px;
    }
    & .hero-content .hero-content-right {
      padding-right: 30px;
    }
  }

  @media screen and (max-width: 1033px) {
    & .hero-content {
      flex-direction: column-reverse;
      padding: 153px 0 30px 0;
    }

    & .hero-content .hero-content-right {
      margin-bottom: 30px;
      padding-right: 23px;
    }
    
    & .hero-content .hero-content-left,
    & .hero-content .hero-content-right { 
      width: 100%;
      padding-left: 23px;
    }
  }
  @media screen and (max-width: 640px) {
    
    & .hero-content .hero-content-left {
      width: 100%;
      padding-right: 40px;
    }
    & .hero-content .hero-content-right { 
      width: 100%;
    }
  }
`
const BgImg = styled(Img)`
  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100%;
  max-width: 100%;
  // z-index: -1;
  // max-height: 100%;
  max-height: 100%;
  border-radius: 4px;
  
  @media (min-width: ${props => props.theme.responsive.small}) {
    // height: ${props => props.height || 'auto'};
  }
  & img {
    // max-height: 80vh;
    object-fit: ${props => props.fit || 'cover'} !important;
    object-position: ${props => props.position || 'center center'} !important;
  }

 /* @media screen and (max-width: 600px) {
    background: url(Embello-Mobile-1.jpg) no-repeat;
    height: 20px;
  } */

  &::before {
    content: '';

    /* position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1; */
  }
`

const Subtitle = styled.h1`
  // position: absolute;
  width: 100%;
  // padding-right: 15px;
  // top: 2em;
  // left: 50%;
  // transform: translate(-50%, -50%);

  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  font-size: 48px;
  text-align: left;
  letter-spacing: 10px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 60px;

  @media screen and (max-width: 600px) {
    line-height: 140%;
    font-size: 24px;
  }
`

const Footer = styled.div`
  position: absolute;
  // width: 100%;
  position: absolute;
  bottom: 0;
  padding-right: 20px;
  padding-bottom: 15px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  font-size: 10px;
  letter-spacing: 0.430833px;
  text-align: right;
  right: 0px;
  color: ${props => (props.isBlue ? '#000000' : '#FFFFFF')};
  a {
    color: ${props => (props.isBlue ? '#000000' : '#FFFFFF')};
  }

  @media screen and (max-width: 600px) {
    font-family: Raleway;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    font-size: 10px;
    text-align: right;
    letter-spacing: 0.409091px;
    padding: 5px 10px;
  }
`

const Hero = props => {
  let imagePhoto = ''
  let responsivePhoto = '';
  
  if (props.image != null && (props.image.fixed || props.image.fluid)) {
    imagePhoto = <BgImg fluid={props.image.fluid} />
  }

  return (
    <Wrapper>
      <div className='hero-background'>
        <div className='hero-background-left'/>
        <div className='hero-background-right'/>
      </div>
      <div className="hero-content">
        <div className="hero-content-left">
          <Subtitle>{props.subtitle}</Subtitle>
          {props.onPressButton &&
            <Button onClick={props.onPressButton} className='signup-button' padding="15px 50px">SIGN UP</Button>
          }
        </div>
        <div className="hero-content-right">
          <div style={{ position: 'relative' }}>
            {props.responsive ? 
              responsivePhoto
            :
              imagePhoto}
            <Footer
              style={{
                color: props.isBlue ? '#000000' : '#FFFFFF',
                a: props.isBlue ? '#000000' : '#FFFFFF',
              }}
              isBlue={props.isBlue}
              dangerouslySetInnerHTML={{
                __html:
                props.footer.childMarkdownRemark != null
                ? props.footer.childMarkdownRemark.html
                : '',
              }}
              />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Hero

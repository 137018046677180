import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import styled from 'styled-components'
import Hero from '../components/Hero'
import HeroIndex from '../components/HeroIndex'
import SEO from '../components/SEO'
import TheTeam from '../components/TheTeam'
import TheTeamCard from '../components/TheTeamCard'
import Img from 'gatsby-image'
import Title from '../components/Title'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

const Wrapper = styled.section`
  text-align: center;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  margin: auto;
`

const SeparatorLine = styled.div`
  width: 155px;
  height: 2px;
  border: solid 1.5px ${props => props.theme.colors.secondary};
  margin: auto;
`

const SeparatorTopLine = styled(SeparatorLine)`
  margin-top: 3em;
`

const CompanyTitle = styled.div`
  padding: 0 25px;
  margin: 4.3em 0 1em 0;
  object-fit: contain;
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 33px;
  text-align: left;
  letter-spacing: 0.239186px;
  color: ${props => props.theme.colors.tertiary};
`

const CompanyDescription = styled.div`
  padding: 0 25px;
  // max-width: 750px;
  margin: auto;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0.0727273px;
  text-align: left;
  
  p {
    line-height: 21px;
    font-weight: 300;
    font-size: 16px;
    margin: 0 0 2em 0;
  }

  color: ${props => props.theme.colors.black};

  h1,
  h2,
  h3 {
    font-weight: 600;
    line-height: 1.25;
    margin: 0 0 1rem 0;
    text-transform: capitalize;
  }

  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.25em;
  }
  h3 {
    font-size: 1em;
  }

  a {
    transition: 0.2s;
    color: ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }

  del {
    text-decoration: line-through;
  }
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }

  ul,
  ol {
    margin: 0 0 2em 0;
  }

  ul {
    li {
      list-style: disc;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
    }
  }

  ol {
    li {
      list-style: decimal;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
    }
  }

  hr {
    border-style: solid;
    border-color: ${props => props.theme.colors.secondary};
    margin: 0 0 2em 0;
  }

  blockquote {
    font-style: italic;
    border-left: 4px solid ${props => props.theme.colors.secondary};
    padding: 0 0 0 0.5em;
  }

  pre {
    margin: 0 0 2em 0;
    border-radius: 2px;
    background: ${props => props.theme.colors.secondary} !important;
    span {
      background: inherit !important;
    }
  }
`

const BgImg = styled(Img)`
  max-width: 219px;
  margin: auto;
  margin-top: 4em;
  margin-bottom: 8em;
`

const AboutUsPageTemplate = ({ data }) => {
  const team = data.allContentfulTeamMember.edges
  const content = data.contentfulAboutUsPage

  let layoutRef = React.createRef();
  
  const handleOnClickSignUp = () => {
    if (layoutRef && 
      layoutRef.current &&
      layoutRef.current.menuContainerRef) {
      
      const menuContainerRef = layoutRef.current.menuContainerRef;
      if (menuContainerRef.current && 
        menuContainerRef.current.menuRef && 
        menuContainerRef.current.menuRef.current &&
        menuContainerRef.current.menuRef.current.openInfluencerRegisterModal
      ) {
        menuContainerRef.current.menuRef.current.openInfluencerRegisterModal();
      }
    }
  }

  return (
    <Layout ref={layoutRef}>
      <Helmet>
        <title>{`${config.siteTitle}`}</title>
      </Helmet>

      {/* <Hero
        title={content.heroImageTitle}
        image={content.heroImage}
        responsiveImage={content.heroImageMobile}
        position={'top center'}
        isBlue
        footer={content.heroImageFooter}
        fixed
        responsive
      /> */}
      <HeroIndex
        subtitle={content.heroTitle}
        image={content.heroImage}
        isBlue
        footer={content.heroImageFooter}
        position={'top center'}
        onPressButton={ () => handleOnClickSignUp() }
        fixed
      />
      <Wrapper>
        <Title paddingTop="1.5em" title={content.heroImageTitle} big/>
        <CompanyTitle>{content.companyTitle}</CompanyTitle>
        <BrowserView>
          <CompanyDescription
            dangerouslySetInnerHTML={{
              __html: content.companyDescription.childMarkdownRemark.html,
            }}
          />

        </BrowserView>
        <MobileView>
          <CompanyDescription
            dangerouslySetInnerHTML={{
              __html: content.companyDescriptionMobile.childMarkdownRemark.html,
            }}
          />
        </MobileView>

        <Title paddingTop="9em" className="subtitle" title={content.teamTitle} />

        <TheTeam team={team}/>

        {/* <Title paddingTop="2em" className="subtitle" title={content.mediaPartnerTitle} />

        <BgImg fluid={content.mediaPartnerLogo.fluid} /> */}
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulTeamMember(sort: { order: ASC, fields: [order] }) {
      edges {
        node {
          id
          name
          title
          heroImage {
            title
            fluid(maxWidth: 1800, maxHeight: 1200) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          descriptionTitle
          description {
            childMarkdownRemark {
              html
              excerpt
            }
          }
          metaDescription {
            internal {
              content
            }
          }
        }
      }
    }
    contentfulAboutUsPage(version: { eq: "current" }) {
      version
      slug
      heroTitle
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroImageMobile {
        title
        fixed(width: 1800) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
      heroImageTitle
      heroImageFooter {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      companyTitle
      companyDescription {
        childMarkdownRemark {
          html
          excerpt
        }
      }
      companyDescriptionMobile {
        childMarkdownRemark {
          html
          excerpt
        }
      }
      teamTitle
      mediaPartnerTitle
      mediaPartnerLogo {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default AboutUsPageTemplate
